import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class ProgramSettingService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/setting');
	}

	public createOrUpdate(settings: any): Promise<any> {
		return axios.post(this.endpointName, settings);
	}
}
